import { http } from "../src/utils/index"

export const userLogin = (data: any) => {
    return http({
        url: '/h5/login/sendMsg',
        method: 'GET',
        params: data
    })
}

// 微信公众号授权
// h5登录
export const h5Login = (data: string) => {
    return http({
        url: `h5/login/index?code=${data}`,
        params: { source: 1 }
    })
}

// 绑定手机号
export const bindPhoneNumber = (data: object) => {
    return http({
        url: '/h5/login/bind',
        method: 'POST',
        data
    })
}

// 获取我的信息
export const getMyData = () => {
    return http({
        url: '/h5/center/index',
        method: 'GET'

    })
}

// 上传图片
export const uploadImg = (data: any) => {
    return http({
        url: '/qiniu/uploadImg',
        method: 'POST',
        data
    })
}

// 上传视频
export const uploadVideo = (d:any) => {
    return http({
        url: '/qiniu/uploadBatchVideo',
        method:'POST',
        data:d,
        timeout: 1000 * 60 * 60 * 10
    })
}