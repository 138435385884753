import axios, { Method } from 'axios';
// 如果是开发环境就直接访问本地的地址，方便本地跨域
export const BASE_URL = process.env.NODE_ENV == 'development' ? process.env.VUE_APP_TARGET_URL : process.env.VUE_APP_BASE_URL
import { Notify } from 'vant';
import Cookies from 'js-cookie';
import { h5AutoLogin } from '../utils/util'
interface axiosType {
    method?: Method,
    url: string,
    data?: any,
    params?: any,
    header?: any,
    timeout?:number
}
const instance = axios.create({
    baseURL: BASE_URL,
    // timeout: 10000,
})
instance.interceptors.request.use((config) => {
    config.headers.token = Cookies.get('token') || ''
    config.headers['Content-Type'] = 'application/json;charset=utf-8'
    config.headers.appid = process.env.VUE_APP_APPLICATION_ID
    return config
})

export const http = (data: axiosType) => {
    return new Promise((resolve, reject) => {
        instance.request(data).then((res: any) => {
            if (res.data.code == 0) {
                resolve(res)
            } else if (res.data.code == 401) {
                // 登录过期
                Cookies.remove('token')
                h5AutoLogin()
            } else if (res.data.code == 110) {
                Cookies.set('isBind', false)
                // 未绑定手机号 先去绑定
                if (res.data.data && res.data.data.token) {
                    Cookies.set('token', res.data.data.token)
                }
                location.href = '/#/login'
            } else if (res.data.code == 102) {
                Cookies.remove('token')
                h5AutoLogin()
            } else if (res.data.code == 2) {
                Notify(res.data.msg)
            } else {
                Notify(res.data.msg)
            }
        }).catch((err: any) => {
            reject(err)
        })
    })

}
