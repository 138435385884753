import Cookies from 'js-cookie'
import { h5Login } from '../../api/user'
import { ImagePreview } from 'vant'

export const MAP_KEY = 'OGSBZ-LFLWV-6RDPF-UFRUX-UQP3J-OGBMG'
export const MAP_NAME = 'xfarmer'
export const QI_LIU_YUN_APPID = '74HLOJZhLwJNnJg-UZ2ImtoiI7BLsKKHhc5j-SjH' // 七流云APPID

// 获取url上面的参数
export const getQueryString = (name: string) => {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]); return null;
}

export const h5AutoLogin = () => {
  const code = getQueryString('code')
  // 第一次通过链接进来，没登录，code也不存在，直接通过微信链接去登录
  if (!Cookies.get('token') && !code) {
    location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${process.env.VUE_APP_APPLICATION_ID}&redirect_uri=${encodeURIComponent(process.env.VUE_APP_TARGET_URL)}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
  } else if (!Cookies.get('token') && code) {
    h5Login(code).then((res: any) => {
      if (res.data.code == 0) {
        Cookies.set('token', res.data.data.token)
        location.href = process.env.VUE_APP_TARGET_URL
      }
    }).catch(err => {
      console.log(err, 'rrrrr')
    })
  }
}

// 时间转换
export const dateFormat = (e: string | number = new Date().getTime()) => {
  const date = new Date(e)
  const y = date.getFullYear()
  const m = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  const h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  const mint = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  const mill = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return {
    YYYY: y,
    MM: m,
    DD: day,
    hh: h,
    mm: mint,
    ss: mill
  }

}

// 去除七牛云的url后面的参数 用于原图展示

export const previewImage = (e: any, pos: number = 0) => {
  if (!e) {
    return;
  }
  const reg = /(\?imageView2\/\d+\/\w+\/\w*\/\w+\/\w*\/\w+\/\w+)/g;
  if (Array.isArray(e)) {
    e.forEach((item) => {
      item.replace(reg, "");
    });
    ImagePreview({
      images: e,
      startPosition: pos,
    });
  } else {
    const url = e.replace(reg, "");
    ImagePreview([url]);
  }
};